label {

    display: inline-block;

    margin-bottom: 0.5rem;

  }

  

  button {

    border-radius: 0;

  }

  

  button:focus {

    outline: 1px dotted;

    outline: 5px auto -webkit-focus-ring-color;

  }

  

  input,

  button,

  select,

  optgroup,

  textarea {

    margin: 0;

    font-family: inherit;

    font-size: inherit;

    line-height: inherit;

  }

  

  button,

  input {

    overflow: visible;

  }

  

  button,

  select {

    text-transform: none;

  }

  

  [role="button"] {

    cursor: pointer;

  }

  

  select {

    word-wrap: normal;

  }

  

  button,

  [type="button"],

  [type="reset"],

  [type="submit"] {

    -webkit-appearance: button;

  }

  

  button:not(:disabled),

  [type="button"]:not(:disabled),

  [type="reset"]:not(:disabled),

  [type="submit"]:not(:disabled) {

    cursor: pointer;

  }

  

  button::-moz-focus-inner,

  [type="button"]::-moz-focus-inner,

  [type="reset"]::-moz-focus-inner,

  [type="submit"]::-moz-focus-inner {

    padding: 0;

    border-style: none;

  }

  

  input[type="radio"],

  input[type="checkbox"] {

    box-sizing: border-box;

    padding: 0;

  }

  

  textarea {

    overflow: auto;

    resize: vertical;

  }

  

  fieldset {

    min-width: 0;

    padding: 0;

    margin: 0;

    border: 0;

  }

  

  .form-control {

    display: block;

    width: 100%;

padding: 0.675rem 1.5rem;

    font-size: 1.6rem;

    line-height: 1.5;

    color: #495057;

    background-color: #fff;

    background-clip: padding-box;

    border: 1px solid #ced4da;

    border-radius: .25rem;

    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

  

  @media (prefers-reduced-motion: reduce) {

    .form-control {

      transition: none;

    }

  }

  

  .form-control::-ms-expand {

    background-color: transparent;

    border: 0;

  }

  

  .form-control:-moz-focusring {

    color: transparent;

    text-shadow: 0 0 0 #495057;

  }

  

  .form-control:focus {

    color: #495057;

    background-color: #fff;

    border-color: #80bdff;

    outline: 0;

    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

  }

  

  .form-control::-webkit-input-placeholder {

    color: #6c757d;

    opacity: 1;

  }

  

  .form-control::-moz-placeholder {

    color: #6c757d;

    opacity: 1;

  }

  

  .form-control:-ms-input-placeholder {

    color: #6c757d;

    opacity: 1;

  }

  

  .form-control::-ms-input-placeholder {

    color: #6c757d;

    opacity: 1;

  }

  

  .form-control::placeholder {

    color: #6c757d;

    opacity: 1;

  }

  

  .form-control:disabled, .form-control[readonly] {

    background-color: #e9ecef;

    opacity: 1;

  }

  

  input[type="date"].form-control,

  input[type="time"].form-control,

  input[type="datetime-local"].form-control,

  input[type="month"].form-control {

    -webkit-appearance: none;

    -moz-appearance: none;

    appearance: none;

  }

  

  select.form-control:focus::-ms-value {

    color: #495057;

    background-color: #fff;

  }

  

  .form-control-file,

  .form-control-range {

    display: block;

    width: 100%;

  }

  

  .col-form-label {

    padding-top: calc(0.375rem + 1px);

    padding-bottom: calc(0.375rem + 1px);

    margin-bottom: 0;

    font-size: inherit;

    line-height: 1.5;

  }

  

  .col-form-label-lg {

    padding-top: calc(0.5rem + 1px);

    padding-bottom: calc(0.5rem + 1px);

    font-size: 1.25rem;

    line-height: 1.5;

  }

  

  .col-form-label-sm {

    padding-top: calc(0.25rem + 1px);

    padding-bottom: calc(0.25rem + 1px);

    font-size: 0.875rem;

    line-height: 1.5;

  }

  

  .form-control-plaintext {

    display: block;

    width: 100%;

    padding: 0.375rem 0;

    margin-bottom: 0;

    font-size: 1rem;

    line-height: 1.5;

    color: #212529;

    background-color: transparent;

    border: solid transparent;

    border-width: 1px 0;

  }

  

  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {

    padding-right: 0;

    padding-left: 0;

  }

  

  .form-control-sm {

    height: calc(1.5em + 0.5rem + 2px);

    padding: 0.25rem 0.5rem;

    font-size: 0.875rem;

    line-height: 1.5;

    border-radius: 0.2rem;

  }

  

  .form-control-lg {

    height: calc(1.5em + 1rem + 2px);

    padding: 0.5rem 1rem;

    font-size: 1.25rem;

    line-height: 1.5;

    border-radius: 0.3rem;

  }

  

  select.form-control[size], select.form-control[multiple] {

    height: auto;

  }

  

  textarea.form-control {

    height: auto;

  }

  

  .form-group {

    margin-bottom: 1rem;

  }

  

  .form-text {

    display: block;

    margin-top: 0.25rem;

  }

  

  .form-row {

    display: -ms-flexbox;

    display: flex;

    -ms-flex-wrap: wrap;

    flex-wrap: wrap;

    margin-right: -5px;

    margin-left: -5px;

  }

  

  .form-row > .col,

  .form-row > [class*="col-"] {

    padding-right: 5px;

    padding-left: 5px;

  }

  

  .form-check {

    position: relative;

    display: block;

    padding-left: 1.25rem;

  }

  

  .form-check-input {

    position: absolute;

    margin-top: 0.3rem;

    margin-left: -1.25rem;

  }

  

  .form-check-input[disabled] ~ .form-check-label,

  .form-check-input:disabled ~ .form-check-label {

    color: #6c757d;

  }

  

  .form-check-label {

    margin-bottom: 0;

  }

  

  .form-check-inline {

    display: -ms-inline-flexbox;

    display: inline-flex;

    -ms-flex-align: center;

    align-items: center;

    padding-left: 0;

    margin-right: 0.75rem;

  }

  

  .form-check-inline .form-check-input {

    position: static;

    margin-top: 0;

    margin-right: 0.3125rem;

    margin-left: 0;

  }

  

  .valid-feedback {

    display: none;

    width: 100%;

    margin-top: 0.25rem;

    font-size: 80%;

    color: #28a745;

  }

  

  .valid-tooltip {

    position: absolute;

    top: 100%;

    left: 0;

    z-index: 5;

    display: none;

    max-width: 100%;

    padding: 0.25rem 0.5rem;

    margin-top: .1rem;

    font-size: 0.875rem;

    line-height: 1.5;

    color: #fff;

    background-color: rgba(40, 167, 69, 0.9);

    border-radius: 0.25rem;

  }

  

  .was-validated :valid ~ .valid-feedback,

  .was-validated :valid ~ .valid-tooltip,

  .is-valid ~ .valid-feedback,

  .is-valid ~ .valid-tooltip {

    display: block;

  }

  

  .was-validated .form-control:valid, .form-control.is-valid {

    border-color: #28a745;

    padding-right: calc(1.5em + 0.75rem);

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");

    background-repeat: no-repeat;

    background-position: right calc(0.375em + 0.1875rem) center;

    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  }

  

  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {

    border-color: #28a745;

    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);

  }

  

  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {

    padding-right: calc(1.5em + 0.75rem);

    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);

  }

  

  .was-validated .custom-select:valid, .custom-select.is-valid {

    border-color: #28a745;

    padding-right: calc(0.75em + 2.3125rem);

    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  }

  

  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {

    border-color: #28a745;

    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);

  }

  

  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {

    color: #28a745;

  }

  

  .was-validated .form-check-input:valid ~ .valid-feedback,

  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,

  .form-check-input.is-valid ~ .valid-tooltip {

    display: block;

  }

  

  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {

    color: #28a745;

  }

  

  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {

    border-color: #28a745;

  }

  

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {

    border-color: #34ce57;

    background-color: #34ce57;

  }

  

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {

    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);

  }

  

  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {

    border-color: #28a745;

  }

  

  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {

    border-color: #28a745;

  }

  

  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {

    border-color: #28a745;

    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);

  }

  

  .invalid-feedback {

    display: none;

    width: 100%;

    margin-top: 0.25rem;

    font-size: 80%;

    color: #dc3545;

  }

  

  .invalid-tooltip {

    position: absolute;

    top: 100%;

    left: 0;

    z-index: 5;

    display: none;

    max-width: 100%;

    padding: 0.25rem 0.5rem;

    margin-top: .1rem;

    font-size: 0.875rem;

    line-height: 1.5;

    color: #fff;

    background-color: rgba(220, 53, 69, 0.9);

    border-radius: 0.25rem;

  }

  

  .was-validated :invalid ~ .invalid-feedback,

  .was-validated :invalid ~ .invalid-tooltip,

  .is-invalid ~ .invalid-feedback,

  .is-invalid ~ .invalid-tooltip {

    display: block;

  }

  

  .was-validated .form-control:invalid, .form-control.is-invalid {

    border-color: #dc3545;

    padding-right: calc(1.5em + 0.75rem);

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");

    background-repeat: no-repeat;

    background-position: right calc(0.375em + 0.1875rem) center;

    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  }

  

  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {

    border-color: #dc3545;

    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);

  }

  

  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {

    padding-right: calc(1.5em + 0.75rem);

    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);

  }

  

  .was-validated .custom-select:invalid, .custom-select.is-invalid {

    border-color: #dc3545;

    padding-right: calc(0.75em + 2.3125rem);

    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

  }

  

  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {

    border-color: #dc3545;

    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);

  }

  

  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {

    color: #dc3545;

  }

  

  .was-validated .form-check-input:invalid ~ .invalid-feedback,

  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,

  .form-check-input.is-invalid ~ .invalid-tooltip {

    display: block;

  }

  

  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {

    color: #dc3545;

  }

  

  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {

    border-color: #dc3545;

  }

  

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {

    border-color: #e4606d;

    background-color: #e4606d;

  }

  

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {

    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);

  }

  

  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {

    border-color: #dc3545;

  }

  

  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {

    border-color: #dc3545;

  }

  

  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {

    border-color: #dc3545;

    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);

  }

  

  .form-inline {

    display: -ms-flexbox;

    display: flex;

    -ms-flex-flow: row wrap;

    flex-flow: row wrap;

    -ms-flex-align: center;

    align-items: center;

  }

  

  .form-inline .form-check {

    width: 100%;

  }

  

  @media (min-width: 576px) {

    .form-inline label {

      display: -ms-flexbox;

      display: flex;

      -ms-flex-align: center;

      align-items: center;

      -ms-flex-pack: center;

      justify-content: center;

      margin-bottom: 0;

    }

    .form-inline .form-group {

      display: -ms-flexbox;

      display: flex;

      -ms-flex: 0 0 auto;

      flex: 0 0 auto;

      -ms-flex-flow: row wrap;

      flex-flow: row wrap;

      -ms-flex-align: center;

      align-items: center;

      margin-bottom: 0;

    }

    .form-inline .form-control {

      display: inline-block;

      width: auto;

      vertical-align: middle;

    }

    .form-inline .form-control-plaintext {

      display: inline-block;

    }

    .form-inline .input-group,

    .form-inline .custom-select {

      width: auto;

    }

    .form-inline .form-check {

      display: -ms-flexbox;

      display: flex;

      -ms-flex-align: center;

      align-items: center;

      -ms-flex-pack: center;

      justify-content: center;

      width: auto;

      padding-left: 0;

    }

    .form-inline .form-check-input {

      position: relative;

      -ms-flex-negative: 0;

      flex-shrink: 0;

      margin-top: 0;

      margin-right: 0.25rem;

      margin-left: 0;

    }

    .form-inline .custom-control {

      -ms-flex-align: center;

      align-items: center;

      -ms-flex-pack: center;

      justify-content: center;

    }

    .form-inline .custom-control-label {

      margin-bottom: 0;

    }

  }

.form-line{

    border: 1px solid gray;

    padding: 4.2rem 1.8rem;

    border-radius: 0.5rem;

}

.form-container{
    padding: 3.8rem;
    background: var(--primary-color-dark);
    h2{
      color: var(--white-text-color);
    }
}